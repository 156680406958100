import { ZStream } from '@mxt/zio/stream';
import { pipe } from 'fp-ts/pipeable';
import React from 'react';
import { Subject, ReplaySubject } from 'rxjs';
import { ZIO } from '@mxt/zio';
import { pipe as pipe$1 } from 'fp-ts/function';
import * as Rx from 'rxjs/operators';

function didUpdate(deps) {
  const subject = new Subject(); // eslint-disable-next-line react-hooks/exhaustive-deps,react-hooks/rules-of-hooks

  React.useEffect(() => {
    subject.next(deps);
  }, deps);
  return pipe(subject, ZStream.fromObservable);
}

function useEffectZ(effect, cleanup, deps, initialState) {
  const [state, setState] = React.useState(initialState);
  React.useEffect(() => {
    let isMounted = true;
    pipe$1(effect, ZIO.flatMap(value => ZIO.effectTotal(() => {
      if (isMounted) {
        setState(value);
      }
    })), ZIO.run({}));
    return () => {
      isMounted = false;

      if (cleanup) {
        pipe$1(cleanup(state), ZIO.run({}));
      }
    };
  }, deps);
  return state;
}

function runDidMount(initialState = null) {
  return effect => useEffectZ(effect, undefined, [], initialState);
}
function runDidMountC(initialState = null) {
  return cleanup => effect => useEffectZ(effect, cleanup, [], initialState);
}

function runWillUnmount(effect) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffectZ(ZIO.unit, () => effect, [], undefined);
}

function subscribe(initialState = null) {
  return stream => {
    const [state, setState] = React.useState(initialState);
    React.useEffect(() => {
      const onDestroy = new ReplaySubject();
      pipe(stream, ZStream.chainEffect(value => ZIO.effect(() => {
        setState(value);
      })), stream => stream({}), Rx.takeUntil(onDestroy), s => s.subscribe());
      return () => {
        onDestroy.next();
        onDestroy.complete();
      };
    }, []);
    return state;
  };
}

const useIsMounted = () => {
  const isMounted = React.useRef(false);
  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return isMounted;
};

const useLoading = (initial = true) => {
  const [isLoading, _setLoading] = React.useState(initial);
  const isMounted = useIsMounted();

  const setLoading = value => ZIO.effectTotal(() => {
    if (isMounted.current) {
      _setLoading(value);
    }
  });

  const bindLoading = effect => pipe(setLoading(true), ZIO.flatMap(() => effect), ZIO.tapBoth(() => setLoading(false), () => setLoading(false)));

  return [isLoading, bindLoading];
};

export { didUpdate, runDidMount, runDidMountC, runWillUnmount, subscribe, useEffectZ, useIsMounted, useLoading };
