"use strict";
/**
 * @since 0.3.2
 */
Object.defineProperty(exports, "__esModule", { value: true });
var Task_1 = require("fp-ts/lib/Task");
var pipeable_1 = require("fp-ts/lib/pipeable");
var _1 = require(".");
var T = _1.getLoggerM(Task_1.task);
/**
 * @since 0.3.2
 */
exports.URI = 'LoggerTask';
/**
 * @since 0.3.2
 */
exports.filter = T.filter;
/**
 * @since 0.3.2
 */
exports.getMonoid = T.getMonoid;
/**
 * @since 0.3.2
 */
exports.loggerTask = {
    URI: exports.URI,
    contramap: T.contramap
};
var contramap = pipeable_1.pipeable(exports.loggerTask).contramap;
exports.contramap = contramap;
