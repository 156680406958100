"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getLoggerM(M) {
    var empty = function () { return M.of(undefined); };
    return {
        contramap: function (fa, f) { return function (b) { return fa(f(b)); }; },
        filter: function (ma, predicate) { return function (a) { return (predicate(a) ? ma(a) : M.of(undefined)); }; },
        getMonoid: function () { return ({
            concat: function (x, y) { return function (a) {
                return M.ap(M.map(x(a), function () { return function () { return undefined; }; }), y(a));
            }; },
            empty: empty
        }); }
    };
}
exports.getLoggerM = getLoggerM;
